@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.me {
    padding: 2rem 0;

    .title {
        margin: 0 0 25px 0;
        text-align: center;

        @media #{$mq-medium-and-over} {
            @include titleH1;
        }
    }

    .articlesList {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: $grid-column-gap;
        margin-bottom: 3.5rem;

        @media #{$mq-medium-and-over} {
            grid-template-columns: repeat(2, 1fr);
        }

        .block {
            margin-bottom: 15px;

            @media #{$mq-medium-and-over} {
                margin-bottom: 30px;
            }
        }
    }
}
