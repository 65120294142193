@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.me {
    grid-column-start: 1;
    grid-column-end: 3;
    height: max-content;

    @media #{$mq-medium-and-over} {
        grid-column-start: 1;
        grid-column-end: 5;
    }

    .baniere {
        align-items: center;
        background-color: $couleur-primaire-dark;
        color: #fff;
        display: flex;
        height: 100%;
        left: 0;
        right: 0;

        .container {
            padding: 2rem 0;

            .content {
                margin-bottom: 1rem;
            }
        }

        .small {
            padding: 1rem 0;
            text-align: center;

            @media #{$mq-medium-and-over} {
                align-items: center;
                display: flex;
                flex-direction: row;
                padding: 2rem 0;
            }

            .content {
                margin-bottom: 0;

                @media #{$mq-medium-and-over} {
                    margin-right: 4rem;
                }
            }

            .title {
                margin-top: 0;
                margin-bottom: 0.5rem;
            }

            p {
                font-size: $font-size-l;
                font-weight: 500;
            }
        }

        em {
            display: none;

            @media #{$mq-medium-and-over} {
                background-color: #fff;
                border-radius: 50%;
                color: $couleur-primaire-regular;
                display: block;
                font-size: 2.7rem;
                padding: 0.15rem;
            }
        }

        a {
            background-color: #fff;
            color: $couleur-primaire-dark;
            padding-left: 2.5rem;
            padding-right: 2.5rem;

            &:hover,
            &:focus {
                background-color: $couleur-primaire-regular;
            }
        }
    }

    :global(.body) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        >* {
            text-align: center;
            vertical-align: middle;
        }

        h4,
        p {
            margin: 0;
        }
    }
}